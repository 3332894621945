import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../../components/page-layout"

export default function BlogArchive({
  data: {
    allMdx: { edges },
  },
  location,
}) {
  const posts = edges
    .filter(
      ({ node }) =>
        node.fields.slug.startsWith("/blog/") && !node.frontmatter.draft
    )
    .map(({ node }) => ({ ...node.frontmatter, url: node.slug }))
    .sort((a, b) => new Date(b.date) - new Date(a.date))

  return (
    <Layout location={location.pathname}>
      <h1>List of Posts</h1>
      <ul>
        {posts.map(({ date, url, title }) => (
          <li key={url}>
            {date} <Link to={`/blog/${url}`}>{title}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArchiveQuery {
    allMdx {
      edges {
        node {
          fields {
            slug
          }
          slug
          frontmatter {
            date
            title
            draft
          }
        }
      }
    }
  }
`
